import { defineComponent } from 'vue'
import LoginStore from "@/store/modules/login";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { useRouter } from "vue-router";
import { Modal } from 'ant-design-vue';

export default defineComponent({
  setup() {
    const loginStore = getModule(LoginStore, store)
    const router = useRouter()
    const handleLayout = () => {
      Modal.confirm({
        title: '退出确认',
        content: '确认退出系统吗？',
        onOk: () => {
          loginStore.layout().then(() => router.push('/login'))
        }
      })
    }
    const userImg='http://8.142.218.12:9000/test/safety-20221112-5e9ea56ecdf54670a5530a533d343ba0.png'
    return () => (
      <a-dropdown
        v-slots={{
          overlay: () => (
            <a-menu>
              {/* <a-menu-item>
                <span>个人中心</span>
              </a-menu-item> */}
              <a-menu-item onClick={handleLayout}>
                <span>退出登录</span>
              </a-menu-item>
            </a-menu>
          )
        }}
      >
        <div class="">
          <a-avatar size="64" src={loginStore.currentUser?.avatarPath?'http://qyapi.ruanwe.com/'+loginStore.currentUser?.avatarPath:userImg} style={{ 'margin-right': '10px' }}>
          </a-avatar>
          <span>{loginStore.currentUser?.username}</span>
        </div>
      </a-dropdown>
    );
  }
})


